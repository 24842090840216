import {
  Box,
  Grid,
  ImageListItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  PageHeaderTypography,
  PageSubheaderTypography,
} from "components/styled/typography/PageHeaderTypography";
import { EmployeesHelpers } from "components/employees/employees.helpers";
import { EmployeesConstants } from "components/employees/employees.constants";
import { StyledDivider } from "components/styled/StyledDivider";
import { ScreenController } from "components/layout/ScreenController";
import React from "react";
import { ContainerBox } from "components/layout/ContainerBox";

const BossWoman: React.FC = () => {
  const theme = useTheme();
  return (
    <>
      <ScreenController.SmallAndDown>
        <Stack bgcolor={theme.palette.grey[50]}>
          <ImageListItem
            sx={{
              p: 2,
              height: "100% !important",
            }}
          >
            <img src={"employees/rebeccah.png"} alt={"rebeccah"} />
          </ImageListItem>
          <Box
            p={2}
            sx={(theme) => ({ backgroundColor: theme.palette.primary.light })}
          >
            <PageHeaderTypography
              color={theme.palette.common.black}
              fontSize={theme.typography.h3.fontSize}
              fontWeight={"600"}
            >
              {EmployeesHelpers.formatName(EmployeesConstants.Rebeccah.name)}
            </PageHeaderTypography>
            <PageSubheaderTypography
              color={theme.otherPalette.black.light}
              fontSize={theme.typography.subtitle1.fontSize}
              fontWeight={"600"}
              pb={2}
            >
              Owner & Founder
            </PageSubheaderTypography>
            <StyledDivider />
            <Typography variant={"body2"} mb={2}>
              {EmployeesConstants.Rebeccah.moreInfo}
            </Typography>
            <Typography variant={"body2"}>
              {EmployeesConstants.Rebeccah.moreInfo2}
            </Typography>
          </Box>
        </Stack>
      </ScreenController.SmallAndDown>
      <ScreenController.MediumAndUp>
        <ContainerBox>
          <Grid
            container
            direction={"row"}
            spacing={8}
            alignContent={"center"}
            justifyContent={"center"}
          >
            <Grid item sm={5} md={4} lg={4}>
              <ImageListItem
                sx={{
                  height: "100% !important",
                }}
              >
                <img src={"employees/rebeccah.png"} alt={"rebeccah"} />
              </ImageListItem>
            </Grid>
            <Grid item sm={6} display={"flex"}>
              <Box
                p={2}
                sx={(theme) => ({
                  backgroundColor: theme.palette.grey[50],
                  flex: 1,
                })}
              >
                <PageHeaderTypography
                  color={theme.palette.common.black}
                  fontSize={theme.typography.h3.fontSize}
                  fontWeight={"600"}
                >
                  {EmployeesHelpers.formatName(
                    EmployeesConstants.Rebeccah.name
                  )}
                </PageHeaderTypography>
                <PageSubheaderTypography
                  color={theme.palette.primary.main}
                  fontSize={theme.typography.subtitle1.fontSize}
                  fontWeight={"600"}
                  pb={2}
                >
                  Owner & Founder
                </PageSubheaderTypography>
                <StyledDivider color={theme.palette.primary.light} />
                <Stack spacing={2}>
                  <Typography
                    sx={(theme) => ({
                      [theme.breakpoints.up("lg")]: {
                        typography: "subtitle2",
                      },
                      [theme.breakpoints.down("lg")]: {
                        typography: "body2",
                      },
                    })}
                  >
                    {EmployeesConstants.Rebeccah.moreInfo}
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      [theme.breakpoints.up("lg")]: {
                        typography: "subtitle2",
                      },
                      [theme.breakpoints.down("lg")]: {
                        typography: "body2",
                      },
                    })}
                  >
                    {EmployeesConstants.Rebeccah.moreInfo2}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </ContainerBox>
      </ScreenController.MediumAndUp>
    </>
  );
};

export default BossWoman;
