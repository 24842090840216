import { Box, Button, Typography } from "@mui/material";
import GridCard from "components/cards/GridCard";
import React, { useState } from "react";
import ServiceDialog from "components/spa-services/ServiceDialog";
import { NavigationHelpers } from "../../helpers/navigation.helpers";
import { SpaService } from "components/spa-services/types";

type ServiceCardProps = {
  service: SpaService;
};

const ServiceCard: React.FC<React.PropsWithChildren<ServiceCardProps>> = (
  props
) => {
  const { service } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <GridCard img={service.img}>
        <Box sx={(theme) => theme.view.col.center}>
          <Typography variant={"subtitle1"}>{service.name}</Typography>
          <Button variant="text" onClick={() => setIsOpen(true)}>
            Learn More
          </Button>
        </Box>
      </GridCard>
      <ServiceDialog
        title={service.name}
        img={service.img}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        contentText={service.more}
        buttons={[
          {
            title: "Book Now",
            onClick: NavigationHelpers.navToBooking,
            sx: { width: "100%" },
          },
        ]}
      />
    </>
  );
};

export default ServiceCard;
