import React from "react";
import {
  Avatar,
  Grid,
  IconButton,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import FadeInContent from "components/layout/FadeInContent/FadeInContent";
import { styled } from "theming/theme";
import { Facebook, Instagram } from "@mui/icons-material";
import { StyledDivider } from "components/styled/StyledDivider";
import { NavigationHelpers } from "../../helpers/navigation.helpers";
import { ContactUsConstants } from "components/contact/contact-us.constants";

const StyledBody = styled(Typography)(({ theme }) => ({
  variant: "body2",
  color: theme.otherPalette.black.light,
  maxWidth: "50%",
  marginBottom: 8,
}));

const PageFooter: React.FC = () => {
  return (
    <FadeInContent>
      <Stack
        pt={4}
        pb={16}
        bgcolor={(theme) => theme.palette.primary.light}
        width={"100%"}
      >
        <Grid container>
          <Grid item lg={1.5} md={1} xs={1} />
          <Grid container spacing={4} lg={10} md={10} xs={10}>
            <Grid item sm={4} xs={12}>
              <Stack>
                <Typography variant={"subtitle1"}>Contact Us</Typography>
                <StyledDivider />
                <StyledBody>{ContactUsConstants.address.split}</StyledBody>
                <Box
                  onClick={() =>
                    NavigationHelpers.emailUs(ContactUsConstants.email.business)
                  }
                >
                  <StyledBody>{ContactUsConstants.email.business}</StyledBody>
                </Box>
                <Box
                  onClick={() =>
                    NavigationHelpers.emailUs(ContactUsConstants.email.boss)
                  }
                >
                  <StyledBody>{ContactUsConstants.email.boss}</StyledBody>
                </Box>
                <Box onClick={() => NavigationHelpers.callUs()}>
                  <StyledBody>
                    Office: {ContactUsConstants.officeMainNumber}
                  </StyledBody>
                </Box>
                <Box
                  onClick={() =>
                    NavigationHelpers.callUs(
                      ContactUsConstants.officeCellNumber
                    )
                  }
                >
                  <StyledBody>
                    Cell: {ContactUsConstants.officeCellNumber}
                  </StyledBody>
                </Box>
                <Stack direction={"row"} spacing={1} mt={1}>
                  <Avatar
                    sx={(theme) => ({
                      bgcolor: theme.palette.primary.main,
                      width: 36,
                      height: 36,
                    })}
                  >
                    <IconButton
                      onClick={NavigationHelpers.navToInstagram}
                      sx={(theme) => ({ color: theme.palette.common.white })}
                    >
                      <Instagram />
                    </IconButton>
                  </Avatar>
                  <Avatar
                    sx={(theme) => ({
                      bgcolor: theme.palette.primary.main,
                      width: 36,
                      height: 36,
                    })}
                  >
                    <IconButton
                      onClick={NavigationHelpers.navToFacebook}
                      sx={(theme) => ({ color: theme.palette.common.white })}
                    >
                      <Facebook />
                    </IconButton>
                  </Avatar>
                </Stack>
              </Stack>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Stack>
                <Typography variant={"subtitle1"}>MD Office Hours</Typography>
                <StyledDivider />
                <StyledBody>
                  Mon — Wed: <b>10am-5pm</b>
                </StyledBody>
                <StyledBody>
                  Thur — Fri: <b>10am-2pm</b>
                </StyledBody>
                <StyledBody>
                  Sat — Sun: <b>Closed</b>
                </StyledBody>
              </Stack>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Stack>
                <Typography variant={"subtitle1"}>Spa Hours</Typography>
                <StyledDivider />
                <StyledBody>
                  Mon — Sat: <b>10am-6pm</b>
                </StyledBody>
                <StyledBody>
                  Sun: <b>Closed</b>
                </StyledBody>
              </Stack>
            </Grid>
          </Grid>
          <Grid item lg={1.5} md={1} xs={1} />
        </Grid>
      </Stack>
    </FadeInContent>
  );
};

export default PageFooter;
