import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { PageSubheaderTypography } from "components/styled/typography/PageHeaderTypography";
import { StyledDivider } from "components/styled/StyledDivider";
import { MuiTelInput } from "mui-tel-input";
import { FavoriteBorder } from "@mui/icons-material";
import { getFunctions, httpsCallable } from "firebase/functions";
import { initializeApp } from "firebase/app";
import { SpaServicesConstants } from "components/spa-services/spa-services.constants";
import { EmailSpaService } from "components/spa-services/types";

type Form = {
  firstName: string;
  lastName: string;
  requestedServices: EmailSpaService[];
  phone: string;
  email: string;
  media: "Google" | "Social Media" | "Referral" | "Other";
  serve: string;
};

const required: (keyof Form)[] = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "requestedServices",
];

const ContactForm: React.FC = () => {
  const theme = useTheme();
  const [form, setForm] = React.useState<Partial<Form>>({
    requestedServices: [],
  });
  const [errors, setErrors] = React.useState<
    Partial<Record<keyof Form, boolean>>
  >({});
  const [sendState, setSendState] = React.useState<"success" | "fail">();

  React.useEffect(() => {
    if (!!sendState) {
      setTimeout(() => setSendState(undefined), 5000);
    }
  }, [sendState]);

  const handleFormChange = <T extends keyof Form>(
    key: keyof Form,
    value: Form[T]
  ) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let err: Partial<Record<keyof Form, boolean>> = {};
    required.forEach((req) => {
      if (!Object.keys(form).includes(req)) {
        err[req] = true;
      }
    });
    setErrors(err);
    if (Object.keys(err).length) {
      return;
    }
    const firebaseApp = initializeApp({
      projectId: "littlecottagemedicalspa",
    });
    const functions = getFunctions(firebaseApp);
    const sendEmail = httpsCallable(functions, "sendEmail");
    setForm({ requestedServices: [] });
    e.currentTarget.reset();
    sendEmail({
      ...form,
      requestedServices: form.requestedServices?.map(
        (ser) => `${ser.group} (${ser.service})`
      ),
    })
      .then((result) => {
        console.log({ result });
        setSendState("success");
      })
      .catch((err) => {
        console.log({ err });
        setSendState("fail");
      });
  };

  return (
    <>
      <Box
        position={"fixed"}
        sx={{
          zIndex: 10,
          top: "auto",
          bottom: { xs: 16, md: 24, lg: 48 },
          px: { xs: 2, md: 3, lg: 4 },
        }}
      >
        {sendState === "fail" ? (
          <Alert severity="warning">
            This is a warning alert — check it out!
          </Alert>
        ) : sendState === "success" ? (
          <Alert severity="success">
            Processed your request successfully. We will get back to you
            promptly.
          </Alert>
        ) : null}
      </Box>
      <Grid container>
        <Grid item lg={1.5} xs={1} />
        <Grid item lg={9} xs={10}>
          <Grid container spacing={{ lg: 8, md: 4, xs: 2 }}>
            <Grid item md={6} xs={12}>
              <PageSubheaderTypography
                color={theme.palette.common.black}
                sx={{
                  [theme.breakpoints.only("xs")]: {
                    fontSize: 28,
                  },
                  pb: 1,
                }}
              >
                We'd love to hear from you
              </PageSubheaderTypography>
              <StyledDivider />
              <Typography
                variant={"subtitle2"}
                sx={{
                  [theme.breakpoints.only("xs")]: {
                    fontSize: theme.typography.body2.fontSize,
                  },
                }}
              >
                Little Cottage is luxury medical spa with a small town feel in
                Spanish Fork, Utah. We would love to know how to best serve you
                and your medical and aesthetic needs. Please contact us to set
                up a consultation today.
              </Typography>
              <Box sx={theme.view.row.center}>
                <FavoriteBorder
                  sx={{
                    [theme.breakpoints.only("xs")]: {
                      height: 125,
                      width: 125,
                    },
                    [theme.breakpoints.not("xs")]: {
                      height: 200,
                      width: 200,
                    },
                    color: theme.palette.secondary.main,
                    py: 1,
                  }}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <form onSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle2"}>Personal Info</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      required
                      fullWidth
                      error={errors.firstName}
                      onChange={(event) =>
                        handleFormChange("firstName", event.currentTarget.value)
                      }
                      id="first-name"
                      label="First Name"
                      variant="filled"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={errors.lastName}
                      required
                      onChange={(event) =>
                        handleFormChange("lastName", event.currentTarget.value)
                      }
                      id="last-name"
                      label="Last Name"
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle2"}>Contact Info</Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      id="email"
                      required
                      error={errors.email}
                      label="Email"
                      onChange={(event) =>
                        handleFormChange("email", event.currentTarget.value)
                      }
                      variant="filled"
                      type={"email"}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <MuiTelInput
                      label="Phone"
                      fullWidth
                      variant={"filled"}
                      required
                      error={errors.phone}
                      defaultCountry={"US"}
                      disableDropdown
                      id={"phone"}
                      value={form.phone}
                      onChange={(newPhone) =>
                        handleFormChange("phone", newPhone)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle2"}>
                      What service(s) are you interested in?{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="requested-services"
                      options={SpaServicesConstants.EmailServices.sort(
                        (a, b) => -b.group.localeCompare(a.group)
                      )}
                      fullWidth
                      groupBy={(opt) => opt.group}
                      onChange={(_, values) =>
                        handleFormChange("requestedServices", values)
                      }
                      disableCloseOnSelect
                      value={form.requestedServices}
                      getOptionLabel={(option) => option.service}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.requestedServices}
                          variant={"filled"}
                          label="Select service(s)"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle2"}>
                      How did you hear about us?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      freeSolo
                      id="media"
                      disableClearable
                      options={["Google", "Social Media", "Referral", "Other"]}
                      fullWidth
                      onSelect={(event) => {
                        // @ts-ignore
                        handleFormChange("media", event.target.value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant={"filled"}
                          label="Select Media"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={"subtitle2"}>
                      How can we serve you?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      onChange={(event) =>
                        handleFormChange("serve", event.currentTarget.value)
                      }
                      id="serve-you"
                      placeholder="Let us know how we can help..."
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type={"submit"}
                      color={"secondary"}
                      variant={"outlined"}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={1.5} xs={1} />
      </Grid>
    </>
  );
};

export default ContactForm;
