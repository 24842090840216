import { Box, Paper, Stack, Typography } from "@mui/material";
import { NavigationHelpers } from "../../helpers/navigation.helpers";
import { Phone } from "@mui/icons-material";
import { StyledDivider } from "components/styled/StyledDivider";
import { ContactUsConstants } from "components/contact/contact-us.constants";
import React from "react";

const CallUs: React.FC = () => {
  return (
    <Box
      sx={(theme) => ({
        bgcolor: theme.palette.secondary.light,
        mt: 4,
        pt: 5,
        pb: 4,
        ...theme.view.col.center,
      })}
    >
      <Stack alignItems={"center"} sx={{ position: "relative" }}>
        <Box sx={{ position: "absolute", top: -78 }}>
          <Paper
            onClick={() => NavigationHelpers.callUs()}
            elevation={0}
            sx={(theme) => ({
              borderRadius: 100_000_000,
              bgcolor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              width: 75,
              height: 75,
              ...theme.view.col.center,
            })}
          >
            <Phone sx={{ width: 40, height: 40 }} />
          </Paper>
        </Box>
        <Typography variant={"subtitle1"}>Have a question?</Typography>
        <Typography variant={"subtitle2"} sx={{ mb: 2 }}>
          Call us at
        </Typography>
        <StyledDivider />
        <Typography
          variant={"h4"}
          fontWeight={"bold"}
          onClick={() => NavigationHelpers.callUs()}
        >
          {ContactUsConstants.officeMainNumber}
        </Typography>
      </Stack>
    </Box>
  );
};

export default CallUs;
