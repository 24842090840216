import { EmailSpaService, SpaService } from "components/spa-services/types";

const SERVICES: SpaService[] = [
  {
    name: "IV Therapy",
    img: "spa-services/iv-therapy_half.jpeg",
    more: "Our IV Therapy blends here at Little Cottage Med Spa can treat anything from nutrient deficiencies to fatigue. We can boost immunity or help you with recovering from a strenuous event.\nWe can set you up for a single treatment, or even for a bi-weekly or monthly infusion.",
  },
  {
    name: "Hormone Therapy",
    img: "spa-services/hormones_half.jpeg",
    more:
      "Bioidentical Hormone Replacement Therapy (BHRT) consists of a range of hormone treatments that help to balance the hormones and replenish low hormones, focusing on symptomatic relief.\nBHRT helps to replenish hormones that are identical on a molecular level to the natural hormones in your body.\n" +
      "BHRT offers various treatment options that address the symptoms you are experiencing. Topical creams, troche, injections, and oral hormone therapies are patient specific and may vary depending which is best for you.",
  },
  {
    name: "Laser Hair Removal",
    img: "spa-services/laser_half.jpeg",
    more: "Are you tired of having to constantly shave or wax? Laser Hair Removal is perfect for you! We use the Alma Harmony Speed AFT SHR Pro.\nAlma’s Super Hair Removal (SHR) method works by gradually heating the dermis to a temperature that effectively damages the hair follicles and prevents re-growth, while avoiding injury to the surrounding tissue.\nIf you are searching for a more permanent way of eliminating hair growth laser hair removal is perfect for you.",
  },
  {
    name: "Tattoo Removal",
    img: "spa-services/tattoo_half.jpeg",
    more: "The high power Q-Switched Nd:YAG 1064 nm laser is ideal for treating darker ink colors (black, blue and green), while the 532 nm wavelength is effective for brighter ink colors (red, orange and yellow).\nThe mechanical Q-Switched effect works by vibrating and breaking down the ink particles in the tattoo. As the area heals, the body’s immune system flushes away the shattered ink particles, causing the tattoo to fade with minimal risk of scarring or hypopigmentation.",
  },
  {
    name: "Blow Dry Bar",
    img: "spa-services/hair_half.jpeg",
    more: "Do you hate wash day?\nWe are here to service you! We are here to wash and style your hair, making wash day so much easier and faster for you.\nWe have single washes as well as packages you can sign up for. Let us take care of you and your hair needs.",
  },
  {
    name: "Skin Rejuvenation",
    img: "spa-services/skin_half.jpeg",
    more:
      "Little Cottage Med Spa carries a wide range of dermal fillers, including Kybella, Juvederm, Volbella, Vollure, and Voluma.\nDermal fillers are injectables that infuse external components into your skin to produce certain cosmetic results, such as adding volume to your cheeks, contouring your lips, hydrating your skin, smoothing the fine lines, etc.\n" +
      "We use the Juvederm family of dermal fillers to provide cosmetic enhancements and smoothen your skin’s surface. Juvederm produces hyaluronic acid-based fillers that supplement your skin’s natural HA reserves to enhance facial volume, provide lip augmentation, and fill out the fine lines and wrinkles on your skin.\n" +
      "Our Utah Med Spa also carries Kybella, a one-of-a-kind injectable that dissolves the unwanted fat tissues under the chin. As such, it reduces sub-mental fullness and treats a “double chin”, giving you a contoured and chiseled jawline. Our cosmetic providers choose the ideal fillers and curate the treatment plan to address your unique needs, producing natural and authentic results.",
  },
  {
    name: "Micro-needling",
    img: "spa-services/needle_half.jpeg",
    more: "Microneedling is collagen induction therapy.\nUsing sterile needles to make controlled wounds in the skin to stimulate the production of collagen and elastin.\nIt’s great for fine lines and wrinkles, as well as scaring, tone, and texture.",
  },
  {
    name: "Facials",
    img: "spa-services/facials_half.jpeg",
    more: "We offer facials to target any skin concerns that you may have, and to help get you to achieve your skin goals. Whether you want to target a special concern or just want to relax we have you covered.\nContact us to make a skin consultation appointment in person or online.",
  },
  {
    name: "Weight Loss Therapy",
    img: "spa-services/weight-loss_half.jpeg",
    more:
      "Weight loss management programs provide support, monitoring, and other assistance to properly and effectively help you to lose weight." +
      "\nIn addition to providing motivation and accountability, our practitioners may prescribe compounded medications to achieve more dramatic, effective weight loss. These include low dose Naltrexone, Semorelin, B12, Phentermine, Wegovy, Semaglutide, Mounjaro, and other fat-burning shots to help individuals reach their weight-loss goals." +
      "\n" +
      "Our medically-supervised program is designed to help any individual safely and effectively reach their specific weight-loss goals. We provide a combination approach to achieve optimum weight loss results, boost metabolism, and effectively burn large amounts of fat–all without feeling like you are suffering or starving.",
  },
  {
    name: "Auto-immune Treatments",
    img: "spa-services/auto-immune_half.jpeg",
    more:
      "There’s usually no single test to diagnose autoimmune disease.\n" +
      "Diagnosis can also be difficult because symptoms can come from other common conditions. Rebeccah Lundell says women and men should seek treatment when they notice new symptoms.\n" +
      "If you’ve been healthy and suddenly you feel fatigue or joint stiffness, don’t downplay that,” she says. “Telling your doctor helps him or her to look closer at your symptoms and run tests to either identify or rule out autoimmune disease.”",
  },
  {
    name: "BOTOX",
    img: "spa-services/cosmetic-injections_half.jpeg",
    more:
      "BOTOX injections are a popular anti-aging treatment, with well-known cosmetic and medical benefits. It works quickly and effectively to improve the signs of aging, rejuvenate facial appearance, and relieve the debilitating symptoms of tension headaches.\n" +
      "There are 43 muscles in the face. The ability to pinpoint the correct spots and a gentle technique are essential to optimizing treatment. If you are considering BOTOX treatments in Utah for cosmetic benefits, you can have confidence in the technical skills, artistry, and dedication of our team. Contact us for the best BOTOX Utah has to offer.",
  },
  {
    name: "Other",
    img: "spa-services/other_half.jpeg",
    more:
      "We offer many other services, including:\n" +
      "\t\tWaxing\n" +
      "\t\tTeeth Whitening\n" +
      "\t\tEyelash Extensions\n" +
      "\t\tMassage Therapy\n" +
      "\t\tBrow Lamination\n",
  },
];

const EmailServices: EmailSpaService[] = [
  { service: "Brow Shape", group: "Waxing" },
  { service: "Upper Lip", group: "Waxing" },
  { service: "Chin", group: "Waxing" },
  { service: "Nostrils", group: "Waxing" },
  { service: "Underarm", group: "Waxing" },
  { service: "Arms", group: "Waxing" },
  { service: "Half Leg", group: "Waxing" },
  { service: "Full Leg", group: "Waxing" },
  { service: "Bikini", group: "Waxing" },
  { service: "Brazilian", group: "Waxing" },
  { service: "Back Or Chest", group: "Waxing" },
  {
    service: "X-SMALL  (Brow, Upper-Lip, Feet, Hands, Areola, Sideburns)",
    group: "Laser Hair Removal",
  },
  {
    service: "SMALL (Underarm, Bikini, Chin, Neck, Naval Strip)",
    group: "Laser Hair Removal",
  },
  {
    service: "MEDIUM (Brazilian, Full Face, Scalp, Half-arm, Chest)",
    group: "Laser Hair Removal",
  },
  {
    service: "LARGE (Half-Leg, Full-Arm, Half-Back)",
    group: "Laser Hair Removal",
  },
  {
    service: "X-LARGE (Full Leg, Full Back, Full Torso)",
    group: "Laser Hair Removal",
  },
  { service: "Teeth Whitening", group: "Teeth" },
  { service: "Whitening W/ A Friend", group: "Teeth" },
  { service: "Tattoo Removal", group: "Laser" },
  { service: "Clear Lift", group: "Laser" },
  { service: "Clear Lift Neck", group: "Laser" },
  { service: "Clear Lift Facial", group: "Laser" },
  { service: "IPL", group: "Laser" },
  { service: "Combo Ipl & Clear Lift", group: "Laser" },
  { service: "Nail Fungus Light Therapy", group: "Laser" },
  { service: "Vascular Lesions", group: "Laser" },
  { service: "Signature 45 Min", group: "Facials" },
  { service: "Custom 60 Min", group: "Facials" },
  { service: "Little Helper 60 Min", group: "Facials" },
  { service: "Monthly Special 55 Min", group: "Facials" },
  { service: "Basic Chemical Peel", group: "Treatment Enhancements" },
  { service: "High Frequency", group: "Treatment Enhancements" },
  { service: "Hydro-jelly Mask", group: "Treatment Enhancements" },
  { service: "Extractions", group: "Treatment Enhancements" },
  { service: "Chemical Peel", group: "Chemical Skin Treatments" },
  { service: "Microneedling", group: "Chemical Skin Treatments" },
  { service: "Dermaplaning", group: "Chemical Skin Treatments" },
  { service: "Microdermabrasion", group: "Chemical Skin Treatments" },
  { service: "Lash Lift", group: "Eyelashes" },
  { service: "Volume Lash Extensions", group: "Eyelashes" },
  { service: "Volume Lash Fill", group: "Eyelashes" },
  { service: "Hybrid Lash Extensions", group: "Eyelashes" },
  { service: "Hybrid Lash Fill", group: "Eyelashes" },
  { service: "Classic Lash Extensions", group: "Eyelashes" },
  { service: "Classic Lash Fill", group: "Eyelashes" },
  { service: "Back Facial", group: "Body Treatments" },
  { service: "Hot Stone Back Facial", group: "Body Treatments" },
  { service: "Back Chemical Peel", group: "Body Treatments" },
  { service: "Brow Lamination", group: "Eyebrows" },
  { service: "Monthly Membership 2x/Month", group: "Blow Dry Bar" },
  { service: "Monthly Membership 4x/Month", group: "Blow Dry Bar" },
  { service: "Single Visit Wash & Style", group: "Blow Dry Bar" },
  { service: "Single Visit Style", group: "Blow Dry Bar" },
  { service: "Weight Loss Consultation", group: "Medical Treatments" },
  { service: "Gut Health Consultation", group: "Medical Treatments" },
  { service: "Low T&Ed Consultation", group: "Medical Treatments" },
  {
    service: "Bio Identical Hormone Replacement Consult",
    group: "Medical Treatments",
  },
  { service: "B12 Shots", group: "Medical Treatments" },
  { service: "60 Min Massage", group: "Massage Therapy" },
  { service: "90 Min Massage", group: "Massage Therapy" },
  { service: "Hot Stone Massage", group: "Massage Therapy" },
  { service: "Pregnancy Massage", group: "Massage Therapy" },
  { service: "Deep Tissue Massage", group: "Massage Therapy" },
  { service: "Couples Massage", group: "Massage Therapy" },
  { service: "Botox", group: "Cosmetic Injections" },
  { service: "Volbella", group: "Cosmetic Injections" },
  { service: "Voluma", group: "Cosmetic Injections" },
  { service: "Migraine Relief", group: "IV Therapy" },
  { service: "The Glow Up", group: "IV Therapy" },
  { service: "Immune Booster", group: "IV Therapy" },
  { service: "Thirst Quencher", group: "IV Therapy" },
  { service: "Myers Cocktail", group: "IV Therapy" },
  { service: "CBD Body Oil", group: "Massage Therapy Enhancements" },
  { service: "Honey Magnolia Oil", group: "Massage Therapy Enhancements" },
];

export const SpaServicesConstants = {
  SERVICES,
  EmailServices,
};
