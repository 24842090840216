import { styled } from "theming/theme";
import { Typography } from "@mui/material";

export const PageHeaderTypography = styled(Typography)(
  ({ theme, ...rest }) => ({
    [theme.breakpoints.up("xs")]: {
      fontSize: theme.typography.h1.fontSize,
      fontFamily: "Cormorant Garamond",
      color: theme.palette.common.white,
      ...rest,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: theme.typography.h4.fontSize,
      fontFamily: "Cormorant Garamond",
      color: theme.palette.common.white,
      ...rest,
    },
  })
);

export const PageSubheaderTypography = styled(Typography)(
  ({ theme, ...rest }) => ({
    [theme.breakpoints.up("xs")]: {
      fontSize: theme.typography.h3.fontSize,
      fontFamily: "Cormorant Garamond",
      fontStyle: "italic",
      fontWeight: "500",
      color: theme.palette.common.white,
      ...rest,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: theme.typography.subtitle2.fontSize,
      fontFamily: "Cormorant Garamond",
      fontStyle: "italic",
      color: theme.palette.common.white,
      ...rest,
    },
  })
);
