export const Colors = {
  GRAY_50: "#FAFAFA",
  GRAY_100: "#F5F5F5",
  GRAY_200: "#EEEEEE",
  GRAY_300: "#E0E0E0",
  GRAY_400: "#BDBDBD",
  GRAY_500: "#9E9E9E",
  GRAY_600: "#757575",
  GRAY_700: "#616161",
  GRAY_800: "#424242",
  GRAY_900: "#212121",
  WHITE: "#FFFFFF",
  BLACK: {
    main: "#1D1F20",
    accent: "#353636",
    light: "#757575",
    dark: "#000000",
  },
  PRIMARY: {
    main: "#D98FB1",
    accent: "#353636",
    light: "#F2D5E3",
    dark: "#BE859D",
  },
  SECONDARY: {
    main: "#E4C765",
    accent: "#E6CE7B",
    light: "#F4E4A1",
    dark: "#C1984C",
  },
  TERTIARY: {
    main: "#80BFB4",
    accent: "#93DBCF",
    light: "#C2F2E5",
    dark: "#74A895",
  },
};
