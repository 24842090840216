import React from "react";
import "./App.css";
import { Box, IconButton, Slide, useScrollTrigger } from "@mui/material";
import Navigation from "components/layout/Navigation";
import { Switch } from "react-router-dom";
import Routes from "./routing/Routes";
import PageFooter from "components/footer/PageFooter";
import { EventNote } from "@mui/icons-material";
import { NavigationHelpers } from "./helpers/navigation.helpers";

const App: React.FC = () => {
  const trigger = useScrollTrigger();
  return (
    <Switch>
      <Box>
        <Navigation />
        <Routes />
        <PageFooter />
        <Box
          position={"fixed"}
          sx={{
            top: "auto",
            bottom: { xs: 16, md: 24, lg: 48 },
            right: { xs: 16, md: 24, lg: 48 },
          }}
        >
          <Slide direction={"up"} in={trigger}>
            <IconButton
              sx={(theme) => ({
                p: 2,
                bgcolor: theme.palette.primary.main,
                color: theme.palette.common.white,
                boxShadow: theme.shadows[5],
              })}
              onClick={NavigationHelpers.navToBooking}
            >
              <EventNote />
            </IconButton>
          </Slide>
        </Box>
      </Box>
    </Switch>
  );
};

export default App;
