import { Route, Switch } from "react-router-dom";
import React from "react";
import Home from "containers/Home";
import About from "containers/About";
import Services from "containers/Services";
import Contact from "containers/Contact";
import NotFound from "containers/NotFound";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/services" component={Services} />
      <Route path="/about" component={About} />
      <Route path="/contact" component={Contact} />
      <Route path={"*"} component={NotFound} />
    </Switch>
  );
};

export default Routes;
