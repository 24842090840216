import React from "react";
import { Stack } from "@mui/material";
import { BoxWithImage, ContainerBox } from "components/layout/ContainerBox";
import {
  PageHeaderTypography,
  PageSubheaderTypography,
} from "components/styled/typography/PageHeaderTypography";
import Employees from "components/employees/Employees";
import SectionWithTitle from "components/layout/SectionWithTitle";
import FadeInContent from "components/layout/FadeInContent/FadeInContent";
import BossWoman from "components/employees/BossWoman";
import SimpleMap from "components/maps/Map";

const About: React.FC = () => {
  return (
    <Stack>
      <FadeInContent>
        <BoxWithImage img={"staff.png"} xs={200}>
          <ContainerBox sx={{ zIndex: 1 }}>
            <Stack spacing={{ xs: 1, md: 2 }} display={"block"}>
              <PageHeaderTypography fontWeight={600}>
                Meet The Team
              </PageHeaderTypography>
              <PageSubheaderTypography fontWeight={400}>
                Talented Professionals Across The Board
              </PageSubheaderTypography>
            </Stack>
          </ContainerBox>
        </BoxWithImage>
      </FadeInContent>
      <FadeInContent>
        <BossWoman />
      </FadeInContent>
      <FadeInContent>
        <SectionWithTitle
          title={"Staff"}
          bgcolor={(theme) => theme.palette.grey[50]}
        >
          <Employees />
        </SectionWithTitle>
      </FadeInContent>
      <FadeInContent>
        <SectionWithTitle
          title={"Location"}
          subtitle={"Click on the pin to open Google Maps"}
          pb={{ md: 0 }}
        >
          <SimpleMap />
        </SectionWithTitle>
      </FadeInContent>
    </Stack>
  );
};

export default About;
