import { Button, MenuItem, Stack, Menu, Divider, Box } from "@mui/material";
import { BoxWithImage, ContainerBox } from "components/layout/ContainerBox";
import React from "react";
import FadeInContent from "components/layout/FadeInContent/FadeInContent";
import SpaServices from "components/spa-services/SpaServices";
import SectionWithTitle from "components/layout/SectionWithTitle";
import {
  PageHeaderTypography,
  PageSubheaderTypography,
} from "components/styled/typography/PageHeaderTypography";
import Reviews from "components/reviews/Reviews";
import { NavigationHelpers } from "../helpers/navigation.helpers";
import ContactForm from "components/contact/ContactForm";
import SimpleMap from "components/maps/Map";
import CallUs from "components/contact/CallUs";

const Home: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack spacing={4}>
      <FadeInContent>
        <BoxWithImage img={"little-cottage.png"}>
          <ContainerBox sx={{ zIndex: 10 }}>
            <Stack spacing={{ xs: 1, md: 2 }} display={"block"}>
              <Box>
                <PageHeaderTypography fontWeight={600}>
                  Little Cottage Medical Spa
                </PageHeaderTypography>
                <PageSubheaderTypography fontWeight={400}>
                  Luxury Spa With a Small Town Feel
                </PageSubheaderTypography>
              </Box>
              <Stack direction={"row"} spacing={2}>
                <Button
                  sx={{ width: 200, opacity: 1 }}
                  variant={"contained"}
                  onClick={NavigationHelpers.navToBooking}
                >
                  Book Now
                </Button>
                <Button
                  sx={{ width: 200 }}
                  variant={"contained"}
                  onClick={handleClick}
                >
                  Online Store
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  sx={{ mt: 0.5 }}
                  onClose={handleClose}
                  MenuListProps={{
                    sx: { width: anchorEl?.offsetWidth },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      NavigationHelpers.navToGlymed();
                    }}
                  >
                    Glymed
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      NavigationHelpers.navToSkinBetter();
                    }}
                  >
                    Skin Better
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      NavigationHelpers.navToEpionce();
                    }}
                  >
                    Epionce
                  </MenuItem>
                </Menu>
              </Stack>
            </Stack>
          </ContainerBox>
        </BoxWithImage>
      </FadeInContent>
      <SectionWithTitle title={"Services"} pt={0}>
        <SpaServices />
      </SectionWithTitle>
      {/*  TODO: Add back in when we have reviews */}
      {/*<SectionWithTitle title={"Reviews"} px={0} py={0}>*/}
      {/*  <Reviews />*/}
      {/*</SectionWithTitle>*/}
      <FadeInContent>
        <SectionWithTitle
          title={"Location"}
          subtitle={"Click on the pin to open Google Maps"}
        >
          <SimpleMap />
        </SectionWithTitle>
      </FadeInContent>
      <FadeInContent>
        <Box py={{ md: 4, xs: 2 }}>
          <ContactForm />
        </Box>
      </FadeInContent>
      <FadeInContent>
        <CallUs />
      </FadeInContent>
    </Stack>
  );
};

export default Home;
