import React from "react";
import { Box, BoxProps, Stack, Typography } from "@mui/material";
import FadeInContent from "components/layout/FadeInContent/FadeInContent";
import { ScreenController } from "components/layout/ScreenController";

type SectionWithTitleProps = {
  title: string;
  subtitle?: string;
} & BoxProps;

const SectionWithTitle: React.FC<
  React.PropsWithChildren<SectionWithTitleProps>
> = (props) => {
  const { title, subtitle, children, ...rest } = props;
  const Subtitle = subtitle && (
    <Typography
      variant="body2"
      letterSpacing={4}
      textAlign={"center"}
      sx={{ fontFamily: "Cormorant Garamond" }}
    >
      {subtitle}
    </Typography>
  );
  return (
    <Box py={3} px={{ md: 0, sm: 4, xs: 4 }} {...rest}>
      <Stack spacing={2} sx={(theme) => ({ ...theme.view.col.center })}>
        <FadeInContent>
          <ScreenController.Mobile>
            <Box sx={(theme) => theme.view.col.center}>
              <Typography
                variant="h3"
                letterSpacing={4}
                textAlign={"center"}
                sx={{ fontFamily: "Cormorant Garamond" }}
              >
                {title}
              </Typography>
              {Subtitle}
            </Box>
          </ScreenController.Mobile>
          <ScreenController.LargeScreen>
            <Box sx={(theme) => theme.view.col.center}>
              <Typography
                variant="h1"
                letterSpacing={4}
                sx={{ fontFamily: "Cormorant Garamond" }}
              >
                {title}
              </Typography>
              {Subtitle}
            </Box>
          </ScreenController.LargeScreen>
        </FadeInContent>
        {children}
      </Stack>
    </Box>
  );
};
export default SectionWithTitle;
