import React from "react";
import SpaServices from "components/spa-services/SpaServices";
import { Box, Button, Grid, ListItem, Stack, Typography } from "@mui/material";
import { BoxWithImage, ContainerBox } from "components/layout/ContainerBox";
import {
  PageHeaderTypography,
  PageSubheaderTypography,
} from "components/styled/typography/PageHeaderTypography";
import { NavigationHelpers } from "../helpers/navigation.helpers";
import FadeInContent from "components/layout/FadeInContent/FadeInContent";
import CallUs from "components/contact/CallUs";
import {
  Balance,
  Bolt,
  Favorite,
  FitnessCenter,
  Healing,
  HeartBroken,
  Kitchen,
  LocalHospital,
  Spa,
} from "@mui/icons-material";
import { ScreenController } from "components/layout/ScreenController";

const MetaServices = [
  {
    name: "Fatigue",
    icon: Bolt,
  },
  {
    name: "Skin Care",
    icon: Spa,
  },
  {
    name: "Weight Issues",
    icon: FitnessCenter,
  },
  {
    name: "Thyroid Health",
    icon: LocalHospital,
  },
  {
    name: "Hormone Imbalances",
    icon: Balance,
  },
  {
    name: "Autoimmune Issues",
    icon: Healing,
  },
  {
    name: "Digestive Health",
    icon: Kitchen,
  },
  {
    name: "Cardio-metabolic Disorders",
    icon: Favorite,
  },
];

const Services: React.FC = () => {
  return (
    <Stack>
      <BoxWithImage img={"spa-services/flowers_half.jpeg"}>
        <ContainerBox sx={{ zIndex: 10 }}>
          <Stack spacing={{ xs: 1, md: 2 }} display={"block"}>
            <Box>
              <PageHeaderTypography fontWeight={600}>
                Our Services
              </PageHeaderTypography>
              <PageSubheaderTypography fontWeight={400}>
                The Support You Need
              </PageSubheaderTypography>
            </Box>
            <Button
              sx={{ width: 250, opacity: 1 }}
              variant={"contained"}
              onClick={NavigationHelpers.navToBooking}
            >
              Schedule an Appointment
            </Button>
          </Stack>
        </ContainerBox>
      </BoxWithImage>
      <Stack spacing={4}>
        <Grid
          py={4}
          container
          sx={(theme) => ({ bgcolor: theme.palette.grey[100] })}
          justifyContent={"center"}
        >
          <Grid item xs={10} md={6}>
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.h3.fontSize,
                fontFamily: "Cormorant Garamond",
                fontStyle: "italic",
                fontWeight: "300",
                textAlign: "center",
              })}
            >
              We are a general family practice
            </Typography>
            <Typography
              sx={(theme) => ({
                fontSize: theme.typography.h5.fontSize,
                fontFamily: "Cormorant Garamond",
                fontStyle: "italic",
                fontWeight: "500",
                textAlign: "center",
              })}
            >
              and see a variety of health conditions
            </Typography>
            <Grid container alignItems={"center"} mt={2}>
              {MetaServices.map((S) => (
                <Grid
                  item
                  xs={6}
                  md={3}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <ListItem>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <S.icon
                        sx={(theme) => ({
                          color: theme.palette.secondary.main,
                          width: { xs: 25, sm: 35 },
                          height: { xs: 25, sm: 35 },
                        })}
                      />
                      <ScreenController.LargeScreen>
                        <Typography variant={"body2"}>{S.name}</Typography>
                      </ScreenController.LargeScreen>
                      <ScreenController.Mobile>
                        <Typography variant={"body1"}>{S.name}</Typography>
                      </ScreenController.Mobile>
                    </Stack>
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Box px={{ md: 0, sm: 4, xs: 4 }}>
          <SpaServices />
        </Box>
        <FadeInContent>
          <CallUs />
        </FadeInContent>
      </Stack>
    </Stack>
  );
};

export default Services;
