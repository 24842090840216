import { Employee } from "components/employees/types";

const formatShortName = (name: Employee["name"]): string =>
  `${name.first} ${name.last[0]}.`;

const formatName = (name: Employee["name"]): string =>
  `${name.first} ${name.last}`;

export const EmployeesHelpers = {
  formatShortName,
  formatName,
};
