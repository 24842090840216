export const ContactUsConstants = {
  email: {
    business: "littlecottagemedspa103@gmail.com",
    boss: "rrlundell2@gmail.com",
  },
  officeCellNumber: "385-375-0494",
  officeMainNumber: "385-344-8070",
  address: {
    full: "103 E 100 S St",
    split: "103 E 100 S St\nSpanish Fork UT, 84660",
  },
};
