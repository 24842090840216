import { ContactUsConstants } from "components/contact/contact-us.constants";

export const NavigationHelpers = {
  navToBooking: () =>
    window.open(
      "https://www.vagaro.com//Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVKfGR4MW5sKlYJidmmVxO0fBgOQ3FRJCwiRQ5emGdP3Odm+oUADrs+1KCvxb1HfGVrvy8NepmObRhUV5adub5hcHVASA0SD8SfbxEce9Qrx7PPeuf/uSTTmrfuKyCxujmp3MeJJt41SPYACFXirF8BYlbSmiykGDBPHwuYrL6VUe9TRZOEdNqD1u8NX8PBzh0chE2x5bUKkh2pdZoywgmVLUw6MvGauo9vkbdUfr0qTNO4nuVINEQv37WK50zSqQiybYvPgb6dQrSAWD1tK6zvpgUszYMuprXo4RoH3LZiOXuY5yOYj0Sq6odB1n9Y8mCu1IjTlhDhlCDQSEXKpKiZufR3Ys9vbo40MvWsxBBOvf7iERZw0CHr3VNrhAzwm04w=="
    ),
  navToGlymed: () => window.open("https://www.glymedplus.com/store/0416245"),
  navToSkinBetter: () =>
    window.open(
      "https://store.skinbetter.com/checkout/checkout.ssp?is=skinusernew&invitecode=2798177#register"
    ),
  navToEpionce: () =>
    window.open("https://www.epionce.com/products/?a=20230328"),
  navToInstagram: () =>
    window.open(
      "https://instagram.com/littlecottage.medspa?igshid=YmMyMTA2M2Y="
    ),
  navToFacebook: () =>
    window.open("https://m.facebook.com/getfitforlifeRR?mibextid=LQQJ4d"),
  callUs: (phoneNumber: string = ContactUsConstants.officeMainNumber) =>
    (window.location.href = `tel:${phoneNumber}`),
  emailUs: (email: string) => (window.location.href = `mailto:${email}`),
};
