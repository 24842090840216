import React from "react";
import { ContainerBox } from "components/layout/ContainerBox";
import {
  PageHeaderTypography,
  PageSubheaderTypography,
} from "components/styled/typography/PageHeaderTypography";
import { Button, Stack, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
const NotFound: React.FC = () => {
  const theme = useTheme();
  return (
    <ContainerBox height={"50vh"} sx={theme.view.col.center}>
      <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
        <PageHeaderTypography
          textAlign={"center"}
          color={theme.palette.common.black}
        >
          Oops!
        </PageHeaderTypography>
        <PageSubheaderTypography
          textAlign={"center"}
          color={theme.palette.common.black}
        >
          Looks like that page doesn't exist.
        </PageSubheaderTypography>
      </Stack>
      <Link
        to={`/`}
        style={{
          textDecoration: "none",
          color: "inherit",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button>Navigate Back To Safety</Button>
      </Link>
    </ContainerBox>
  );
};

export default NotFound;
