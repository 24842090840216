import { Divider } from "@mui/material";
import React from "react";
import { styled } from "theming/theme";

export const StyledDivider = styled(Divider)(({ theme, ...rest }) => ({
  width: 24,
  marginBottom: 16,
  color: theme.otherPalette.black.light,
  ...rest,
}));
