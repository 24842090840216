import { ScreenController } from "components/layout/ScreenController";
import {
  AppBar,
  Box,
  Button,
  Collapse,
  IconButton,
  ListItem,
  Slide,
  Stack,
  Toolbar,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FlexBox } from "components/layout/FlexBox";
import { StyledDivider } from "components/styled/StyledDivider";

const HideOnScroll: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {/*@ts-ignore*/}
      {children}
    </Slide>
  );
};

const Navigation: React.FC = () => {
  const theme = useTheme();
  const { location } = useHistory();
  const [selectedTab, setSelectedTab] = useState<string>(location.pathname);
  const buttons = ["Services", "About", "Contact"];
  const [show, setShow] = React.useState<boolean>(false);
  const handleClick = () => {
    setShow((value) => !value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTab]);

  return (
    <>
      <HideOnScroll>
        <AppBar
          sx={{ bgcolor: theme.palette.background.default, boxShadow: "none" }}
        >
          <Toolbar
            sx={{
              [theme.breakpoints.up("xs")]: {
                ...theme.view.row.right,
                p: theme.spacing(1, 4),
              },
              [theme.breakpoints.only("xs")]: {
                ...theme.view.row.between,
                p: theme.spacing(1, 2),
              },
            }}
            variant="dense"
          >
            <ScreenController.LargeScreen>
              <Stack spacing={4} direction={"row"}>
                <Link to="/">
                  <img
                    src={"logo.png"}
                    alt={"logo"}
                    onClick={() => setSelectedTab("")}
                  />
                </Link>
                {buttons.map((button) => (
                  <Link
                    to={`/${button.toLowerCase()}`}
                    key={button}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{
                        color: theme.otherPalette.black.main,
                        ...(selectedTab
                          .toLowerCase()
                          .includes(button.toLowerCase()) && {
                          fontWeight: "bold",
                        }),
                      }}
                      onClick={() => {
                        setSelectedTab(button);
                      }}
                    >
                      {button}
                    </Button>
                  </Link>
                ))}
              </Stack>
            </ScreenController.LargeScreen>
            <ScreenController.Mobile flex={1}>
              <FlexBox.Col>
                <FlexBox.RowBetween>
                  <Link to="/">
                    <img
                      src={"logo.png"}
                      alt={"logo"}
                      onClick={() => setSelectedTab("")}
                    />
                  </Link>
                  <IconButton onClick={handleClick}>
                    {show ? <Close /> : <Menu />}
                  </IconButton>
                </FlexBox.RowBetween>
                <Collapse
                  in={show}
                  sx={{
                    ...theme.view.col.center,
                  }}
                >
                  {buttons.map((button) => (
                    <Box sx={theme.view.col.center} key={button}>
                      <ListItem sx={theme.view.col.center} key={button}>
                        <Button
                          sx={{ color: theme.otherPalette.black.main }}
                          onClick={() => {
                            setSelectedTab(button);
                            handleClick();
                          }}
                        >
                          <Link
                            to={`/${button.toLowerCase()}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            {button}
                          </Link>
                        </Button>
                      </ListItem>
                      <StyledDivider />
                    </Box>
                  ))}
                </Collapse>
              </FlexBox.Col>
            </ScreenController.Mobile>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar sx={{ pb: 1 }} />
    </>
  );
};

export default Navigation;
