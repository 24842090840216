import React from "react";
import GoogleMapReact from "google-map-react";
import { LocationOn } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const Location = ({ text }: { text: string; lat: any; lng: any }) => (
  <Tooltip
    title={text}
    onClick={() => {
      const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        text
      )}`;
      window.open(url);
    }}
  >
    <LocationOn
      sx={(theme) => ({
        height: 40,
        width: 40,
        color: theme.palette.primary.main,
        position: "absolute",
      })}
    />
  </Tooltip>
);

export default function SimpleMap() {
  const defaultProps = {
    center: {
      lat: 40.10872,
      lng: -111.65271,
    },
    zoom: 15,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "50vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBLhhDxW3haAkqaijWya4-Zyh6rZ3bfaAU" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <Location
          lat={40.10872}
          lng={-111.65271}
          text="103 E 100 S St Spanish Fork UT, 84660"
        />
      </GoogleMapReact>
    </div>
  );
}
