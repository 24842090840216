import { Grid } from "@mui/material";
import FadeInContent from "components/layout/FadeInContent/FadeInContent";
import React from "react";

type ImageGridProps<T> = {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
};

const ImageGrid = <T,>(props: React.PropsWithChildren<ImageGridProps<T>>) => {
  const { items, renderItem } = props;
  return (
    <Grid container>
      <Grid item lg={1.5} md={1} />
      <Grid container spacing={4} lg={9} md={10}>
        {items.map((item, i) => (
          <Grid item lg={4} md={6} sm={6} xs={12} key={`image:${i}`}>
            <FadeInContent>{renderItem(item)}</FadeInContent>
          </Grid>
        ))}
      </Grid>
      <Grid item lg={1.5} md={1} />
    </Grid>
  );
};

export default ImageGrid;
