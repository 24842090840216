import React from "react";
import { Box, Card, CardActions, ImageListItem, Paper } from "@mui/material";

type GridCardProps = {
  img: string;
  CardFooter?: React.ReactNode;
};

const GridCard: React.FC<React.PropsWithChildren<GridCardProps>> = (props) => {
  const { img, CardFooter, children } = props;

  return (
    <Card>
      <ImageListItem
        sx={{
          height: "100% !important",
        }}
      >
        {children && (
          <Box
            sx={{
              zIndex: 1,
              position: "absolute",
              bottom: 16,
              width: "100%",
            }}
          >
            <Paper sx={(theme) => ({ marginX: 2, p: theme.spacing(1, 2) })}>
              {children}
            </Paper>
          </Box>
        )}
        <img src={img} alt={""} />
      </ImageListItem>
      {CardFooter && (
        <CardActions sx={{ flexDirection: "row", p: 2, height: 75 }}>
          {CardFooter}
        </CardActions>
      )}
    </Card>
  );
};

export default GridCard;
