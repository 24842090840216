import React from "react";
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  ListItem,
  Stack,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { ScreenController } from "components/layout/ScreenController";

type ServiceDialogProps = {
  onClose?: () => void;
  img: string;
  contentText: string;
  buttons?: ButtonProps[];
} & Pick<DialogProps, "open" | "title">;

const ServiceDialog: React.FC<React.PropsWithChildren<ServiceDialogProps>> = (
  props
) => {
  const { contentText, img, buttons, ...rest } = props;
  const theme = useTheme();
  const isMobile = window.outerWidth <= theme.breakpoints.values["sm"];
  return (
    <Dialog {...rest} maxWidth={"md"}>
      <DialogTitle
        variant={"subtitle1"}
        sx={(theme) => ({
          color: theme.palette.common.black,
          ...theme.view.row.between,
          p: 1,
          pl: 2,
        })}
      >
        {props.title}
        {props.onClose ? (
          <IconButton
            onClick={props.onClose}
            sx={(theme) => ({
              color: theme.palette.primary.main,
            })}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          spacing={{ sm: 4, xs: 1 }}
        >
          <img
            src={img}
            style={{
              height: "auto",
              width: "50%",
              display: isMobile ? "none" : "block",
            }}
            alt={props.title}
          />
          <Box
            sx={{
              p: { sm: 4, xs: 2 },
              bgcolor: theme.palette.grey[100],
              height: "auto",
              ...theme.view.col.center,
              borderRadius: 2,
            }}
          >
            <Stack
              spacing={1}
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              flex={1}
            >
              <Stack
                spacing={1}
                height={350}
                overflow={"scroll"}
                sx={{
                  bgcolor: theme.palette.common.white,
                  borderRadius: 2,
                  p: { sm: 4, xs: 2 },
                }}
              >
                {contentText.split("\n").map((text) =>
                  text.includes("\t\t") ? (
                    <ListItem component={"li"}>
                      <DialogContentText
                        variant={"body1"}
                        fontSize={(theme) => ({
                          sm: theme.typography.h6.fontSize,
                          xs: (theme.typography.body1.fontSize as number) + 2,
                        })}
                      >
                        {text}
                      </DialogContentText>
                    </ListItem>
                  ) : (
                    <DialogContentText
                      variant={"body1"}
                      fontSize={(theme) => ({
                        sm: theme.typography.h6.fontSize,
                        xs: (theme.typography.body1.fontSize as number) + 2,
                      })}
                    >
                      {text}
                    </DialogContentText>
                  )
                )}
              </Stack>
              <img
                src={"flowers/lotus.png"}
                style={{
                  height: 150,
                  width: 150,
                }}
                alt={"lotus"}
              />
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
      {buttons && !!buttons.length && (
        <DialogActions sx={{ paddingTop: 0 }}>
          {buttons.map((bu) => (
            <Button {...bu}>{bu.title}</Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ServiceDialog;
