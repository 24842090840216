import { Employee } from "components/employees/types";

const Rebeccah: Employee = {
  name: {
    first: "Rebeccah",
    last: "Lundell",
  },
  speciality: "Founder",
  moreInfo:
    "Rebeccah Opened Little Cottage MedSpa & Boutique in 2023. Rebeccah loves all things beauty from skin to hair. She has a passion for fitness and health. She decided to combine her passions into one and open a practice that can meet patients' health needs. She enjoys helping people feel their best by optimizing hormones, and getting to the root cause of their symptoms to help improve health.",
  moreInfo2:
    "Rebeccah grew up in Tennessee. When she isn’t working, Rebeccah loves to spend time with her husband and children, and looks for opportunities to serve in her community.",
};

const employees: Employee[] = [
  // {
  //   name: {
  //     first: "Dr. Randy",
  //     last: "Lundell",
  //   },
  //   speciality: "DO, Little Cottage MedSpa Medical Director",
  //   moreInfo:
  //     "Dr. Randy Lundell, DO is a Family Medicine Doctor, he graduated in 2003 from Western University of Health Sciences in California before serving in the Army as a military Doctor for 9 years.",
  // },
  {
    name: {
      first: "Audrey",
      last: "Cuevas",
    },
    speciality: "Master Esthetician",
    moreInfo:
      "Practicing self-care is a big part of being an Aesthetician and is what I love to help with. From performing a Medical Spa service to product education and showing that you're in good hands, when it comes to your skin.",
  },
  {
    name: {
      first: "Carsyn",
      last: "Thompson",
    },
    speciality: "Master Esthetician",
    moreInfo:
      "Hi I'm Carsyn!! I'm from California. I love hanging out with friends, family and the people I love. on a normal day you can find me at target probably around twice a day and of course swig!",
  },
  {
    name: {
      first: "Grace",
      last: "Crowther",
    },
    speciality: "Hair Stylist",
    moreInfo:
      "I love dancing and singing, I love crafts and doing anything outside when it’s warm! I also love to crochet and style hair!",
  },
  {
    name: {
      first: "Hallie",
      last: "Moore",
    },
    speciality: "Hair Stylist",
    instagram: "https://instagram.com/hals_hair22?igshid=YmMyMTA2M2Y=",
    moreInfo:
      "I am a graduating senior from Spanish Fork high school. I love to travel and have been all over the country. I want to major in business to further my cosmetology career.",
  },
  {
    name: {
      first: "Madison",
      last: "Laforett",
    },
    speciality: "Hair Stylist",
    instagram: "https://instagram.com/beautybar_bymadi?igshid=YmMyMTA2M2Y=",
    moreInfo:
      "I'm Madi! I love doing hair and anything beauty, I live in Santaquin. I'm married to my high school sweetheart. I love my dogs more than anything. I love spending time with my family, I enjoy going to my husband's rodeos and cheering him on. I also love camping, hunting, fishing and pretty much anything outdoors.",
  },
  {
    name: {
      first: "Haylee",
      last: "Vane",
    },
    speciality: "Hair Stylist",
    moreInfo:
      "I love doing blowouts and scalp treatments. Helping you feel more confident is my goal. I am open to talking about anything on your mind or we can do a silent service if you just need some relaxation. ",
  },
  {
    name: {
      first: "David",
      last: "Carillo",
    },
    speciality: "Massage Therapist",
    moreInfo:
      "I've been an LMT for the past 3 years and it has been a very rewarding career. I love helping my clients from recovering from an injury to a full relaxation type of massage. I try my best to accommodate to my clients needs to the best of my abilities. ",
  },
  {
    name: {
      first: "Oaklie",
      last: "A",
    },
    speciality: "Receptionist",
    moreInfo:
      "My name is Oaklie, I’m the receptionist at little cottage med spa. I love everything outdoors you will normally catch me riding my dirt bike or horses or working on old muscle cars in my shop.",
  },
];

export const EmployeesConstants = {
  employees,
  Rebeccah,
};
