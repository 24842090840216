import React from "react";
import EmployeeCard from "components/employees/EmployeeCard";
import { EmployeesConstants } from "components/employees/employees.constants";
import ImageGrid from "components/layout/ImageGrid";
import { Employee } from "components/employees/types";

const Employees: React.FC = () => {
  return (
    <ImageGrid<Employee>
      items={EmployeesConstants.employees}
      renderItem={(em) => <EmployeeCard employee={em} />}
    />
  );
};

export default Employees;
