import { styled } from "theming/theme";
import { Box } from "@mui/material";
import React from "react";

export const ContainerBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.between("sm", "md")]: {
    padding: theme.spacing(4),
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(8),
  },
}));

const BoxWithImageStyled = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%)",
    zIndex: 1,
  },
  ...theme.view.col.left,
}));

export const BoxWithImage: React.FC<
  React.PropsWithChildren<{ img: string; xs?: number }>
> = (props) => (
  <BoxWithImageStyled
    sx={{
      backgroundImage: `url(${props.img})`,
      height: { lg: 700, md: 600, sm: 500, xs: props.xs || 400 },
      width: "100%",
    }}
  >
    {props.children}
  </BoxWithImageStyled>
);
