import React from "react";
import ContactForm from "components/contact/ContactForm";
import { Box, Stack } from "@mui/material";
import CallUs from "components/contact/CallUs";
import FadeInContent from "components/layout/FadeInContent/FadeInContent";

const Contact: React.FC = () => {
  return (
    <Stack>
      <Box py={{ md: 8, xs: 4 }}>
        <ContactForm />
      </Box>
      <FadeInContent>
        <CallUs />
      </FadeInContent>
    </Stack>
  );
};

export default Contact;
