import React from "react";
import ServiceCard from "components/spa-services/ServiceCard";
import ImageGrid from "components/layout/ImageGrid";
import { SpaServicesConstants } from "components/spa-services/spa-services.constants";
import { SpaService } from "components/spa-services/types";

const SpaServices: React.FC = () => {
  return (
    <ImageGrid<SpaService>
      items={SpaServicesConstants.SERVICES}
      renderItem={(service) => <ServiceCard service={service} />}
    />
  );
};

export default SpaServices;
