import GridCard from "components/cards/GridCard";
import { Box, Button, Collapse, IconButton, Typography } from "@mui/material";
import { ExpandLess, ExpandMore, Instagram } from "@mui/icons-material";
import React from "react";
import { Employee } from "components/employees/types";
import { EmployeesHelpers } from "components/employees/employees.helpers";

type EmployeeCardProps = {
  employee: Employee;
};

const EmployeeCard: React.FC<EmployeeCardProps> = (props) => {
  const { employee } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <GridCard
      img={`employees/${employee.name.first.toLowerCase()}.png`}
      CardFooter={
        <>
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" fontWeight={"bold"} lineHeight={1}>
              {EmployeesHelpers.formatShortName(employee.name)}
            </Typography>
            <Typography variant="subtitle2">{employee.speciality}</Typography>
            {employee.secondaryRole && (
              <Typography
                variant="body2"
                color={(theme) => theme.palette.grey[600]}
              >
                {employee.secondaryRole}
              </Typography>
            )}
          </Box>
          {employee.instagram && (
            <IconButton
              sx={(theme) => ({ color: theme.palette.common.black })}
              onClick={() => window.open(employee.instagram)}
            >
              <Instagram sx={{ fontSize: 40 }} />
            </IconButton>
          )}
        </>
      }
    >
      {employee.moreInfo && (
        <>
          <Button
            onClick={() => setIsOpen(!isOpen)}
            variant={"text"}
            startIcon={isOpen ? <ExpandLess /> : <ExpandMore />}
            sx={{ width: "100%", justifyContent: "flex-start" }}
          >
            {isOpen ? "Close" : `Learn More About ${employee.name.first}`}
          </Button>
          <Collapse in={isOpen}>
            <Typography variant={"body2"} sx={{ wordBreak: "break-word" }}>
              {employee.moreInfo}
            </Typography>
          </Collapse>
        </>
      )}
    </GridCard>
  );
};
export default EmployeeCard;
