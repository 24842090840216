import { createTheme, ThemeOptions } from "@mui/material/styles";
import { CSSObject } from "@mui/material/styles";
import { createStyled } from "@mui/system";
import { Colors } from "theming/colors";

const DisplayFlexRowBase: CSSObject = {
  display: "flex",
  flexDirection: "row",
};

const DisplayFlexColumnBase: CSSObject = {
  display: "flex",
  flexDirection: "column",
};

export const themeOptions: ThemeOptions = {
  otherPalette: {
    tertiary: Colors.TERTIARY,
    black: Colors.BLACK,
  },
  palette: {
    primary: {
      ...Colors.PRIMARY,
      contrastText: Colors.WHITE,
    },
    secondary: Colors.SECONDARY,
    // black: Colors.TERTIARY,
    grey: {
      50: Colors.GRAY_50,
      100: Colors.GRAY_100,
      200: Colors.GRAY_200,
      300: Colors.GRAY_300,
      400: Colors.GRAY_400,
      500: Colors.GRAY_500,
      600: Colors.GRAY_600,
      700: Colors.GRAY_700,
      800: Colors.GRAY_800,
      900: Colors.GRAY_900,
    },
    background: {
      default: Colors.WHITE,
    },
    common: {
      black: Colors.BLACK.main,
      white: Colors.WHITE,
    },
  },
  view: {
    flex: {
      display: "flex",
    },
    row: {
      std: DisplayFlexRowBase,
      center: {
        ...DisplayFlexRowBase,
        justifyContent: "center",
        alignItems: "center",
      },
      right: {
        ...DisplayFlexRowBase,
        justifyContent: "right",
        alignItems: "center",
      },
      left: {
        ...DisplayFlexRowBase,
        justifyContent: "left",
        alignItems: "center",
      },
      between: {
        ...DisplayFlexRowBase,
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
    col: {
      std: DisplayFlexColumnBase,
      center: {
        ...DisplayFlexColumnBase,
        justifyContent: "center",
        alignItems: "center",
      },
      right: {
        ...DisplayFlexColumnBase,
        justifyContent: "center",
        alignItems: "right",
      },
      left: {
        ...DisplayFlexColumnBase,
        justifyContent: "center",
        alignItems: "left",
      },
      between: {
        ...DisplayFlexColumnBase,
        justifyContent: "center",
        alignItems: "space-between",
      },
    },
  },
  typography: {
    h1: {
      fontSize: 64,
      fontFamily: "Karla",
    },
    h2: {
      fontSize: 48,
      fontFamily: "Karla",
    },
    h3: {
      fontSize: 40,
      fontFamily: "Karla",
    },
    h4: {
      fontSize: 32,
      fontFamily: "Karla",
    },
    subtitle1: {
      fontSize: 24,
      fontFamily: "Karla",
    },
    subtitle2: {
      fontSize: 20,
      fontFamily: "Karla",
    },
    body1: {
      fontSize: 14,
      fontFamily: "Karla",
    },
    body2: {
      fontSize: 16,
      fontFamily: "Karla",
    },
  },
  components: {
    MuiMenu: {
      defaultProps: {
        elevation: 2,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.body2.fontSize,
          color: theme.palette.primary.dark,
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: "Karla",
          fontSize: 16,
        }),
      },
    },
  },
};

export const theme = createTheme(themeOptions);

export const styled = createStyled({ defaultTheme: theme });
